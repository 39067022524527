<template>
  <vx-card title="Product B2B Import">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Option</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="options"
          :clearable="false"
          v-model="selectedOption"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File (xls, xlsx)</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          type="file"
          ref="fileInput"
          accept=".xlsx, .xls"
          @change="handleChangeFile"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-input-group flex space-x-3">
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleImport()"
          >Import</vs-button
        >
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-download"
          @click="handleDownloadTemplate()"
          >Template Download</vs-button
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Import">
            <data-table-log-import
              :baseUrl="this.baseUrl"
              :draw="draw"
              @logImportLine="handleLogImportLine"
            ></data-table-log-import>
          </vs-tab>
          <vs-tab label="Import Line">
            <data-table-log-import-line
              :baseUrl="this.baseUrl"
              :logImportID="this.logImportID"
            ></data-table-log-import-line>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import DataTableLogImport from "./DataTableLogImport.vue";
import DataTableLogImportLine from "./DataTableLogImportLine.vue";
export default {
  components: {
    Datepicker,
    vSelect,
    DataTableLogImport,
    DataTableLogImportLine,
  },

  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/product-btwob-rules",

      activeTab: 0,
      optionRouteGroup: [],
      selectedRouteGroup: null,
      month: null,

      optionTerritory: [],
      selectedTerritory: null,
      optionZone: [],
      selectedZone: null,
      description: "",

      options: ["New", "Add", "Replace"],
      selectedOption: "New",

      optionalProductTeam: [],
      selectedProductTeam: null,
      file: null,
      draw: 0,
      logImportID: 0,
    };
  },
  methods: {
    setDefault() {
      this.activeTab = 0;
      this.selectedRouteGroup = this.optionRouteGroup[0];
      this.selectedTerritory = this.optionTerritory[0];
      this.selectedOption = "New";
      this.selectedProductTeam = null;
      this.month = null;
      this.description = "";
      this.file = null;
      this.logImportID = 0;
      this.$refs.fileInput.value = null;
    },
    handleLogImportLine(id) {
      this.activeTab = 1;
      this.logImportID = id;
    },
    handleDownloadTemplate() {
      this.$vs.loading();
      var fileTitle = "PRODUCT_B2B_TEMPLATE_" + this.selectedOption;

      this.$http
        .get(this.baseUrl + "/import/template", {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    handleImport() {
      if (!this.file) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let params = {
        option: this.selectedOption,
        description: this.description,
      };

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("data", JSON.stringify(params));

      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/import/create", formData)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.$vs.loading.close();
            this.draw++;
            this.setDefault();
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });

      console.log(params);
    },
    handleChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      // console.log(this.file);
      if (this.file) {
        const reader = new FileReader();

        // reader.onload = (e) => {
        //   /* Parse data */
        //   const bstr = e.target.result;
        //   const wb = XLSX.read(bstr, { type: "binary" });
        //   /* Get first worksheet */
        //   const wsname = wb.SheetNames[0];
        //   const ws = wb.Sheets[wsname];
        //   /* Convert array of arrays */
        //   const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        // };

        reader.readAsBinaryString(this.file);
      }
    },
  },
  mounted() {
    this.getTerritory();
  },
  watch: {},
};
</script>
